import { buildApi } from "@/utils/ajax";

const basicApi = buildApi("/portal/ops");

// 服务管理列表
export const useBasicServicePageApi = basicApi({
  url: "/basicservice/page",
  withCredentials: true
});

// 修改记录
export const useServiceUpdateLogPageApi = basicApi({
  url: "/serviceupdatelog/page",
  withCredentials: true
});

// 服务供应商关系列表
export const useServiceListApi = basicApi({
  url: "/service/list",
  withCredentials: true
});

// 修改服务供应商关系
export const useServiceUpdateApi = basicApi({
  url: "/service/update",
  withCredentials: true
});

// 系统设置/数据字典/字典列表
export const useDictListApi = basicApi({
  url: "/dict/list",
  withCredentials: true
});

// 系统设置/数据字典/根据code查询字典信息以及子字典信息
export const useDictQueryByCodeApi = basicApi({
  url: "/dict/query_by_code",
  withCredentials: true
});

// 系统设置/数据字典/新增字典
export const useDictAddApi = basicApi({
  url: "/dict/add",
  withCredentials: true
});
// 系统设置/数据字典/修改字典
export const useDictUpdateApi = basicApi({
  url: "/dict/update",
  withCredentials: true
});

// 系统设置/数据字典/删除字典
export const useDictDeleteApi = basicApi({
  url: "/dict/delete",
  withCredentials: true
});

// 系统设置/账号管理/获取用户列表（分页）
export const useUserPageApi = basicApi({
  url: "/rbac/user/page",
  withCredentials: true
});

// 系统设置/账号管理/获取用户列表（不分页）
export const useUserListApi = basicApi({
  url: "/rbac/user/list",
  withCredentials: true
});

// 系统设置/账号管理/角色列表（不分页）
export const useRoleListApi = basicApi({
  url: "/rbac/role/list",
  withCredentials: true
});

// 系统设置/账号管理/转让超级管理员角色
export const useTransferSuperRoleApi = basicApi({
  url: "/rbac/role/transfer_super_role",
  withCredentials: true
});

// 系统设置/账号管理/分配角色
export const useGrantRoleApi = basicApi({
  url: "/rbac/user/grant_role",
  withCredentials: true
});

// 系统设置/角色权限管理/新增角色
export const useAddRoleApi = basicApi({
  url: "/rbac/role/add",
  withCredentials: true
});

// 系统设置/角色权限管理/角色权限详情
export const useRoleDetailApi = basicApi({
  url: "/rbac/role/detail",
  withCredentials: true
});

// 系统设置/角色权限管理/菜单树
export const useMenuTreeApi = basicApi({
  url: "/rbac/menu/menu_tree",
  withCredentials: true
});

// 系统设置/角色权限管理/更新角色的权限信息
export const useUpdateMenuApi = basicApi({
  url: "/rbac/role/update_menu",
  withCredentials: true
});

// 系统设置/角色权限管理/删除角色
export const useRoleDeleteApi = basicApi({
  url: "/rbac/role/delete",
  withCredentials: true
});
