/**
 * 搜索头
 */
export const searchHeaderOptions = [
  {
    field: "service_name",
    label: "服务项",
    type: "input",
    iw: 160,
    props: {
      placeholder: "请输入服务名称"
    },
    rules: { min: 2, message: "最少输入两个字符", trigger: "blur" }
  }
];
