<template>
  <div>
    <p class="fw-600">服务配置</p>

    <!-- 列表 -->
    <p-table
      class="mt-16"
      :dataSource="tableDataSource"
      id="service-configuration-table"
    >
      <p-t-column type="seq" width="60" title="序号" />
      <p-t-column field="service_name" min-width="228" title="服务项" />
      <p-t-column
        field="service_provider_name"
        width="296"
        title="服务商名称"
      />
      <p-t-column
        field="sale_price"
        min-width="291"
        title="销售单价（元/次）"
        fixed="right"
      >
        <template v-slot="{ row }">
          <a-input-number
            min="0"
            max="999.99"
            :value="row.sale_price / 100"
            :precision="2"
            @change="handlePriceChange($event, row)"
            style="width:88px;"
          />
        </template>
      </p-t-column>
      <p-t-column
        field="is_default_service_provider"
        width="140"
        title="默认服务商"
        fixed="right"
      >
        <template v-slot="{ row }">
          <RadioGroup
            v-model:value="row.is_default_service_provider"
            @change="handleDefaultProvider($event, row)"
          >
            <Radio value="YES">启用</Radio>
          </RadioGroup>
        </template>
      </p-t-column>
    </p-table>

    <div class="btn-box d-flex justify-center mt-28">
      <Button type="primary" @click="handleSave">确定</Button>
      <Button class="ml-16" @click="handleClose">取消</Button>
    </div>
  </div>
</template>

<script>
import { reactive, ref, toRaw } from "vue";
import { Radio, Button, message } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { useServiceListApi, useServiceUpdateApi } from "@/apis/system";
import $await from "@/utils/await";

export default {
  name: "service-configuration",
  components: { Radio, RadioGroup: Radio.Group, Button },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    childRow: {
      type: Object,
      default: () => {}
    }
  },
  setup(props, ctx) {
    const serviceListApi = useServiceListApi();
    const serviceUpdateApi = useServiceUpdateApi();

    const defaultList = ref([]);

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {}
    });

    Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          service_code: props.childRow.service_code
        };
        const [, res] = await $await(serviceListApi(data));
        defaultList.value = JSON.parse(JSON.stringify(res));
        return { records: res };
      }
    });

    // 改变价格
    const handlePriceChange = (e, row) => {
      row.sale_price = e * 100;
    };

    // 改变默认服务商
    const handleDefaultProvider = (e, row) => {
      tableDataSource.data.map(item => {
        if (row.id !== item.id) {
          return (item.is_default_service_provider = "NO");
        }
      });
    };

    // 保存时，将有改动的那一条信息传给后端
    const handleSave = async () => {
      let data = [];
      const after = JSON.parse(JSON.stringify(toRaw(tableDataSource.data)));
      const before = toRaw(defaultList.value);
      after.forEach(i => {
        delete i._XID;
        before.forEach(j => {
          if (i.id === j.id && JSON.stringify(i) !== JSON.stringify(j)) {
            data.push(i);
          }
        });
      });

      const [, res] = await $await(serviceUpdateApi({ value: data }));
      if (res) {
        message.success("保存成功！");
        ctx.emit("reflesh");
        handleClose();
      }
    };

    const handleClose = () => {
      ctx.emit("handleClose", false);
    };

    return {
      tableDataSource,
      handleSave,
      handleClose,
      handlePriceChange,
      handleDefaultProvider
    };
  }
};
</script>
