<template>
  <div>
    <div class="d-flex">
      <p class="fw-600">服务商名称： {{ childRow.service_provider_name }}</p>
      <p class="ml-48 fw-600">服务项： {{ childRow.service_name }}</p>
    </div>

    <p-table
      class="mt-16"
      :dataSource="tableDataSource"
      id="service-configuration-table"
    >
      <p-t-column type="seq" width="60" title="序号" fixed="left" />
      <p-t-column field="field_name" min-width="168" title="修改项" />
      <p-t-column field="source_content" width="222" title="修改前内容" />
      <p-t-column field="target_content" width="222" title="修改后内容" />
      <p-t-column field="updater" min-width="209" title="修改人" />
      <p-t-column field="update_time" width="172" title="修改时间">
        <template v-slot="{ row }">
          <span>{{
            formatToDateTime(row.update_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </template>
      </p-t-column>
    </p-table>
  </div>
</template>

<script>
import { reactive } from "vue";
import { Table } from "@wlhy-web-components/common";
import { useServiceUpdateLogPageApi } from "@/apis/system";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "service-configuration",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    childRow: {
      type: Object,
      default: () => {}
    }
  },
  setup(props) {
    const serviceUpdateLogPageApi = useServiceUpdateLogPageApi();

    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      }
    });

    Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          basic_service_id: props.childRow.id,
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(serviceUpdateLogPageApi(data));
        return useTableDataRecords(res);
      }
    });

    return {
      tableDataSource,
      formatToDateTime
    };
  }
};
</script>
