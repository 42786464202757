<template>
  <div class="page-main-box" id="serviceOrderBox">
    <!-- 有数据 -->
    <div class="pt-24 pl-16 pr-16">
      <!-- 搜索头 -->
      <search-header :schemaData="schema" @getDataList="handleFormSearch" />

      <!-- 列表 -->
      <div :style="{ height: tableHeight + 'px' }">
        <p-table
          id="service-order-table"
          :dataSource="tableDataSource"
          height="auto"
        >
          <p-t-column type="seq" width="60" title="序号" fixed="left" />
          <p-t-column field="service_code" min-width="166" title="服务编码" />
          <p-t-column field="service_name" min-width="136" title="服务项" />
          <p-t-column
            field="sale_price"
            min-width="166"
            title="销售价"
            align="right"
          >
            <template v-slot="{ row }">
              <span>{{ (row.sale_price / 100).toFixed(2) }}</span>
            </template>
          </p-t-column>
          <p-t-column field="update_time" min-width="230" title="修改时间">
            <template v-slot="{ row }">
              <span>{{
                formatToDateTime(row.update_time, "yyyy-MM-dd hh:mm:ss")
              }}</span>
            </template>
          </p-t-column>
          <p-t-column field="updater" min-width="140" title="修改人" />
          <p-t-column width="130" title="操作" fixed="right">
            <template v-slot="{ row }">
              <Button type="link" @click="handleAmendRecords(row)">
                修改记录
              </Button>
              <Button
                v-if="$store.getters.roles.includes('ServiceDeploy')"
                type="link"
                @click="handleSeviceConfig(row)"
                >配置</Button
              >
            </template>
          </p-t-column>
        </p-table>
      </div>
    </div>

    <!-- 修改记录页面 -->
    <Drawer
      class="drawer-box"
      @close="handleClose"
      v-model:visible="visible"
      id="#serviceOrderBox"
      placement="bottom"
      height="560"
    >
      <component
        v-if="visible"
        :childRow="childRow"
        :is="currentStepComponent"
        @handleClose="handleClose"
        @reflesh="getList"
      ></component>
    </Drawer>
  </div>
</template>

<script>
import { ref, reactive, toRaw, onMounted } from "vue";
import { Button } from "ant-design-vue";
import { Table } from "@wlhy-web-components/common";
import { searchHeaderOptions } from "./Constants";
import ServiceConfiguration from "./components/ServiceConfiguration/Main.vue";
import AmendantRecord from "./components/AmendantRecord/Main.vue";
import { useBasicServicePageApi } from "@/apis/system";
import useSearchForm from "@/hooks/UseSearchForm";
import $await from "@/utils/await";
import useTableDataRecords from "@/hooks/useTableDataRecords";
import useTableHeight from "@/hooks/useTableHeight";
import Drawer from "@/components/Drawer";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default {
  name: "service-order",
  components: { ServiceConfiguration, AmendantRecord, Button, Drawer },
  setup() {
    const basicServicePageApi = useBasicServicePageApi();
    const { filter, handleFormSearch } = useSearchForm(searchHeaderOptions);

    // table高度
    const tableHeight = ref();

    // 传入弹窗数据
    const childRow = ref({});

    // 弹窗控制
    const visible = ref(false);

    const currentStepComponent = ref();

    // 列表数据
    const tableDataSource = reactive({
      loading: false,
      data: [],
      error: "",
      meta: {
        total: 0,
        pageSize: 20,
        currentPageNo: 1
      },
      sum: {
        data: [],
        loading: false,
        error: ""
      }
    });

    const getList = Table.useTableConfig({
      filter,
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          ...toRaw(filter.value),
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const [, res] = await $await(basicServicePageApi(data));
        return useTableDataRecords(res);
      }
    });

    // 打开修改记录弹窗
    const handleAmendRecords = row => {
      childRow.value = toRaw(row);
      currentStepComponent.value = "AmendantRecord";
      visible.value = true;
    };
    // 打开服务配置弹窗
    const handleSeviceConfig = row => {
      childRow.value = toRaw(row);
      currentStepComponent.value = "ServiceConfiguration";
      visible.value = true;
    };
    const handleClose = () => {
      visible.value = false;
    };

    onMounted(() => {
      tableHeight.value = useTableHeight();
    });

    return {
      getList,
      currentStepComponent,
      tableHeight,
      childRow,
      handleFormSearch,
      handleClose,
      visible,
      handleAmendRecords,
      handleSeviceConfig,
      tableDataSource,
      schema: searchHeaderOptions,
      formatToDateTime
    };
  }
};
</script>

<style lang="less" scoped>
#serviceOrderBox {
  position: relative;
  overflow: hidden;
}
</style>
